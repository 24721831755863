import { render, staticRenderFns } from "./role.vue?vue&type=template&id=404eb529&scoped=true&"
import script from "./role.ts?vue&type=script&lang=ts&"
export * from "./role.ts?vue&type=script&lang=ts&"
import style0 from "./role.scoped.scss?vue&type=style&index=0&id=404eb529&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "404eb529",
  null
  
)

export default component.exports